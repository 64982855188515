import {
  useCoAgent,
  useCopilotChat,
  useCopilotMessagesContext,
} from '@copilotkit/react-core';
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { Reference } from '../../pages/ai-adviser/components/ChatComponents/References';
import { Role, Message } from '@copilotkit/runtime-client-gql';
import { MessageFeedback } from '../../pages/ai-adviser/components/ChatComponents/ResponseFeedback';
import { useSnackbar } from 'notistack';
import { ERROR_MESSAGES } from '../constants';
import { getStoredUserId } from '../utils/authHelpers';

type AgentState = {
  user_id: string;
  project_id: string;
  references?: Reference[];
  follow_up_questions?: string[];
  message_feedback?: MessageFeedback;
};

interface ChatbotContextValue {
  activeProject: string;
  setActiveProject: Dispatch<SetStateAction<string | null>>;
  chatbotState: AgentState;
  feedback: MessageFeedback;
  setFeedback: Dispatch<SetStateAction<MessageFeedback | null>>;
  getLastAgentMessage: () => Message;
  visibleMessages: Message[];
  isLoading: boolean;
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
}

const ChatbotContext = createContext<ChatbotContextValue>(null);

export const ChatbotProvider = ({ children }) => {
  const [activeProject, setActiveProject] = useState<string>('');
  const [feedback, setFeedback] = useState<MessageFeedback>(null);
  const userId = getStoredUserId();
  const { messages, setMessages } = useCopilotMessagesContext();
  const { visibleMessages, isLoading } = useCopilotChat();
  const { enqueueSnackbar } = useSnackbar();
  const { state, setState } = useCoAgent<AgentState>({
    name: 'chat_agent',
  });

  const getLastAgentMessage = () => {
    return messages
      .slice()
      .reverse()
      .find(
        (message) => message.isTextMessage() && message.role === Role.Assistant
      );
  };

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      user_id: userId,
      project_id: activeProject,
      message_feedback: feedback,
    }));
  }, [userId, activeProject, feedback?.message_id]);

  useEffect(() => {
    if (messages.length !== 0) {
      try {
        localStorage.setItem(
          `copilotkit-messages-${userId}`,
          JSON.stringify(messages)
        );
        // store feedback if exists
        if (feedback) {
          localStorage.setItem(
            `messageFeedback-${userId}`,
            JSON.stringify(feedback)
          );
        }
      } catch (e) {
        console.error(e);
        enqueueSnackbar(ERROR_MESSAGES.SAVE_DATA_ERROR, {
          variant: 'error',
          preventDuplicate: true,
        });
      }
    }
  }, [JSON.stringify(messages), JSON.stringify(feedback)]);

  useEffect(() => {
    if (activeProject) {
      localStorage.setItem(`activeProject-${userId}`, activeProject);
    }
  }, [activeProject]);

  const value: ChatbotContextValue = {
    activeProject,
    setActiveProject,
    chatbotState: state,
    feedback,
    setFeedback,
    getLastAgentMessage,
    visibleMessages,
    isLoading,
    setMessages,
  };

  return (
    <ChatbotContext.Provider value={value}>{children}</ChatbotContext.Provider>
  );
};

export const useChatbotContext = () => useContext(ChatbotContext);
